import React from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import First from '../mencoursedetails/First'

function Main({course, lopUrl}) {
  return (
    <>
        <Header/>
        <Bredcom title="Home" subtitle="Course Details" lopUrl={lopUrl} />
        <First courseName={course} lopUrl={lopUrl} />
        {/* <Brand/> */}
    </>
  )
}

export default Main