import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <footer className="footer-bg footer-p pt-90" style={{ backgroundColor: "#125875", backgroundImage: "url(assets/img/bg/footer-bg.png)" }} >
                <div className="footer-top pb-70">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xl-4 col-lg-4 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>About Us</h2>
                                    </div>
                                    <div className="f-contact">
                                        <p>
                                            The Manas Gangotri Nursing College is approved by Government of Jharkhand and
                                            Jharkhand Nurses Registration Council, RIMS, Ranchi (Jharkhand).
                                        </p>
                                    </div>
                                    <div className="footer-social mt-10">
                                        <a href="#">
                                            <i className="fab fa-facebook-f" />
                                        </a>
                                        <a href="#">
                                            <i className="fab fa-instagram" />
                                        </a>
                                        <a href="#">
                                            <i className="fab fa-twitter" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Our Links</h2>
                                    </div>
                                    <div className="footer-link">
                                        <ul>
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/about"> About</Link>
                                            </li>
                                            <li>
                                                <Link to="/courses">Courses</Link>
                                            </li>
                                            <li>
                                                <Link to="/contact"> Contact Us</Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/blog">Blog </Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-sm-6">
                                {/* <div className="footer-widget mb-30">
                        <div className="f-widget-title">
                        <h2>Latest Post</h2>
                        </div>
                        <div className="recent-blog-footer">
                        <ul>
                            <li>
                            <div className="thum">
                                {" "}
                                <img src="assets/img/blog/s-blogimg-01.png" alt="img" />
                            </div>
                            <div className="text">
                                {" "}
                                <Link to="/blog-details">
                                Nothing impossble to need hard work
                                </Link>
                                <span>7 March, 2020</span>
                            </div>
                            </li>
                            <li>
                            <div className="thum">
                                {" "}
                                <img src="assets/img/blog/s-blogimg-02.png" alt="img" />
                            </div>
                            <div className="text">
                                {" "}
                                <Link to="/blog-details">
                                Nothing impossble to need hard work
                                </Link>
                                <span>7 March, 2020</span>
                            </div>
                            </li>
                        </ul>
                        </div>
                    </div> */}
                            </div>
                            <div className="col-xl-3 col-lg-3 col-sm-6">
                                <div className="footer-widget mb-30">
                                    <div className="f-widget-title">
                                        <h2>Contact Us</h2>
                                    </div>
                                    <div className="f-contact">
                                        <ul>
                                            <li>
                                                <i className="icon fal fa-phone" />
                                                <span>
                                                    <Link to="tel:+918882650799">+91 88826 50799</Link>
                                                    <br />
                                                    <Link to="tel:+919102047466">+91 91020 47466</Link>
                                                </span>
                                            </li>
                                            <li className='d-flex align-items-center'>
                                                <i className="icon fal fa-envelope" />
                                                <span>
                                                    <Link to="mailto:manasgangotrinursing@gmail.com">manasgangotrinursing@gmail.com</Link>
                                                </span>
                                            </li>
                                            <li>
                                                <i className="icon fal fa-map-marker-check" />
                                                <span>
                                                    Bhawanthpur, Nagar Untari
                                                    <br />
                                                    Garhwa, Jharkhand 822129
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-wrap">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="copy-text">
                                    <Link to="/">
                                        <img src="assets/img/logo/manas_gangotri.png" alt="Manas Gangotri Logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-4 text-center"></div>
                            <div className="col-lg-4 text-right text-xl-right">
                                Copyright © Manas Gangotri  Nursing College 2023<br /> All rights reserved
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer