import React from 'react'
import Headertwo from '../headertwo/Main'
import Slider from '../menhometwo/Slider'
import Child from '../menhometwo/Child'
import Service from '../menhometwo/Service'
import Aboutkids from '../menhometwo/Aboutkids'
import Goging from '../menhometwo/Goging'
import Aboutvideo from '../menhometwo/Aboutvideo'
import Events from '../menhometwo/Events'
import Teacher from '../menhometwo/Teacher'
import Testimonial from '../menhometwo/Testimonial'
import Blog from '../menhometwo/Blog'
import Blueslider from '../menhometwo/Blueslider'

function Main() {
  return (
   <>
        <Headertwo/>
        <Slider/>
        <Child/>
        <Service/>
        <Aboutkids/>
        <Goging/>
        <Aboutvideo/>
        <Events/>
        <Teacher/>
        <Testimonial/>
        <Blog/>
        <Blueslider/>
   </>
  )
}

export default Main