import React from 'react'

function University() {
    return (
        <>
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="s-about-img p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                                <img src="assets/img/features/college_evening.jpg" alt="img" />
                                {/* <div className="about-text second-about three-about">
                                    <span>
                                        25 <sub>+</sub>
                                    </span>
                                    <p>Years of Experience</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h5>
                                        <i className="fal fa-graduation-cap" /> About Our College
                                    </h5>
                                    <h2>A Few Words About the College</h2>
                                </div>
                                <p className="txt-clr">
                                    MANAS GANGOTRI NURSING COLLEGE is located in Bhawanthpur, Nagar
                                    Untari, Garhwa, Jharkhand. The college is established in 2023 with the aim of
                                    providing quality medical education to aspiring nursing trainees in the
                                    region.
                                </p>
                                <p>
                                    Currently, the college offers an undergraduate program in nursing field,
                                    specifically ANM and GNM degree.
                                    We have our parental hospital in which we emphasis on hands-on training
                                    and practical experience, in which the trainees will be in rotation of the
                                    various department.
                                </p>
                                <p>
                                    Our institution has got excellent infrastructure to provide quality education. The college
                                    also has well-equipped laboratories and library.
                                    The faculty at MG NURSING COLLEGE are highly qualified and experience.
                                </p>
                                <p>
                                    At MG NURSING COLLEGE we strive to provide a holistic educational
                                    experience that enriches the mind, body, and soul. Join us today and embark
                                    on a journey of discovery and transformation.
                                </p>
                                {/* <div className="about-content2">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="green2">
                                                <li>
                                                    <div className="abcontent">
                                                        <div className="ano">
                                                            <span>01</span>
                                                        </div>{" "}
                                                        <div className="text">
                                                            <h3>Doctoral Degrees</h3>{" "}
                                                            <p>
                                                                consectetur adipiscing elit sed do eiusmod tem incid
                                                                idunt.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="abcontent">
                                                        <div className="ano">
                                                            <span>02</span>
                                                        </div>{" "}
                                                        <div className="text">
                                                            <h3>Global Students</h3>{" "}
                                                            <p>
                                                                consectetur adipiscing elit sed do eiusmod tem incid
                                                                idunt.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default University