import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'

const faqItems = [
    {
        id: 'headingThree',
        question: '01 Cras turpis felis, elementum sed mi at arcu ?',
        answer: 'Our community is being called to reimagine the future. As the only university where a renowned design school comes together with premier colleges, we are making learning more relevant and transformational. We are enriched by the wide range.',
    },
    {
        id: 'headingOne',
        question: '02 Vestibulum nibh risus, in neque eleifendulputate sem ?',
        answer: 'Our community is being called to reimagine the future. As the only university where a renowned design school comes together with premier colleges, we are making learning more relevant and transformational. We are enriched by the wide range.',
    },
    {
        id: 'headingTwo',
        question: '03 Donec maximus, sapien id auctor ornare ?',
        answer: 'Our community is being called to reimagine the future. As the only university where a renowned design school comes together with premier colleges, we are making learning more relevant and transformational. We are enriched by the wide range.',
    },
];

const courses = [
    {
        id: 1,
        url: 'aux-nursing',
        illustration: 'assets/img/bg/couress-img-1.jpg',
        title: 'Auxiliary Nursing & Midwifery',
        overview: `
        <p>
            Auxiliary Nursing and Midwifery (ANM) is a two-year diploma course (including six months internship). ANM is the only option for the non-science stream plus- two students who want to go for nursing. The curriculum covers community health nursing, mental health, child health nursing, midwifery, health center management and human body & hygiene.
        </p>
        <p>
            The ANM course aims to give basic knowledge in health care like operating medical equipment, setting up of operation theatre, timely medication to patients, maintaining health records etc. The course completed candidates should be able to provide first aid and primary medical care to the patient. Some of the places that hire ANM nurses are community health centers, dispensaries, nursing homes, private and government hospitals.
        </p>
        `,
        eligiblity: `
            <p>1. The minimum educational requirement shall be the passing of 12 Years of schooling (10+2) Year course recognized by CBSE/ICSE/SSSCE/HSCE with aggregate of 45% marks or a recognized equivalent examination minimum age for admissions.</p>
            <p>2. The minimum age for admission shall be 17 Years on or before 31st December of the year in which the admission is sought.</p>
            <p>3. The maximum educational requirement shall be 35 Years.</p>
        `,
        note: `
        <p>
            All Original Certificates submitted for admission will be retained by the college until successful completion of the course and returned after settlement of all dues.
        </p>
        <p>
            Students withdrawing their admission in the middle of the course will have to pay the entire course fees. Fees towards Eligibility and Registration
        </p>
        <p>
            An additional charge would be levied for issuing Provisional/Migration/Final Degree and Convocation.
        </p>
        <p>
            *As per clinical requirment-Clinical and Transpotation Fee will be charge <br>
            *Hostel Fee-22000 Rs/ Per Year <br>
            *Further details will be shared at time of admission <br>
        </p>
        `,
        duration: '2years (4 Semester)',
        formFee: '₹ 500/- (one time only)',
        admissionFee: '₹ 1,000/-<br>(one time only)',
        developmentFee: '₹ 2,000/-<br>(one time only)',
        tutionFee: '₹ 20,500/-<br>Per Semester',
        examFee: '₹ 1,000/-<br>Per Semester',
        totalFee: '₹ 89,500/-'
    },
    {
        id: 2,
        url: 'gen-nursing',
        illustration: 'assets/img/bg/medical_lab.jpg',
        title: 'General Nursing & Midwifery',
        overview: `
        <p>
            GNM program offers knowledge in the fundamentals of nursing. The students would get knowledge to maintain health and equipment. The aspirants could be able to treat the situation of children, women, and old aged people.
        </p>
        <p>
            The curriculum includes Psychology, Educational Methods and Media for Teaching in Practice of Nursing, Midwifery and Gynaecology, Pediatric Nursing, Microbiology, Computer Education, Personal Hygiene, English, Environment Hygiene, Nutrition, Health Education, Orthopedic Nursing, Mental Health Nursing, Medical Surgical Nursing, Midwifery, Gynaecological Nursing, Child Health Nursing, and more.
        </p>
        `,
        eligiblity: `
            <p>1. The minimum educational requirement shall be the passing of 12 Years of schooling (10+2) Year course recognized by CBSE/ICSE/SSSCE/HSCE with aggregate of 45% marks or a recognized equivalent examination minimum age for admissions.</p>
            <p>2. The minimum age for admission shall be 17 Years on or before 31st December of the year in which the admission is sought.</p>
            <p>3. The maximum educational requirement shall be 35 Years.</p>
        `,
        note: `
        <p>
            All Original Certificates submitted for admission will be retained by the college until successful completion of the course and returned after settlement of all dues.
        </p>
        <p>
            Students withdrawing their admission in the middle of the course will have to pay the entire course fees. Fees towards Eligibility and Registration
        </p>
        <p>
            An additional charge would be levied for issuing Provisional/Migration/Final Degree and Convocation.
        </p>
        <p>
            *As per clinical requirment-Clinical and Transpotation Fee will be charge <br>
            *Hostel Fee-22000 Rs/ Per Year <br>
            *Further details will be shared at time of admission <br>
        </p>
        `,
        duration: '3years (8 Semester)',
        formFee: '₹ 500/- (one time only)',
        admissionFee: '₹ 1,000/-<br>(one time only)',
        developmentFee: '₹ 2,000/-<br>(one time only)',
        tutionFee: '₹ 45,500/-<br>Per Semester',
        examFee: '₹ 1,000/-<br>Per Semester',
        totalFee: '₹ 2,79,500/-'
    },
]


function First({ courseName, lopUrl }) {

    const [activeIndex, setActiveIndex] = useState(null);
    const [course, setCourse] = useState(courses[0]);

    useEffect(() => {
        const reqCourse = courses.find(c => c.url === courseName);
        console.log(course, reqCourse)
        setCourse(reqCourse);
    }, [courseName])

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                <h2>{course.title}</h2>
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        <figure className="image">
                                            <img src={course.illustration} alt="Course illustration" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    {/* <div className="course-meta2 review style2 clearfix mb-30">
                                        <ul className="left">
                                            <li>
                                                <div className="author">
                                                    <div className="thumb">
                                                        <img src="assets/img/bg/testi_avatar.png" alt="image" />
                                                    </div>
                                                    <div className="text">
                                                        <Link to="/single-courses-2">Robto Jone</Link>
                                                        <p>Teacher</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="categories">
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#" className="course-name">
                                                            Photoshop
                                                        </a>
                                                        <p>Categories</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul className="right">
                                            <li className="price">$59.00</li>
                                        </ul>
                                    </div> */}
                                    <h3>Course Overview</h3>
                                    {parse(course.overview)}
                                    <h4>ELIGIBILITY CRITERIA</h4>
                                    {parse(course.eligiblity)}
                                    <h4>Note</h4>
                                    {parse(course.note)}
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3">
                                        <h3>Course Features</h3>
                                        <ul className="project-info clearfix">
                                            <li>
                                                <span className="icon fal fa-clock" />{" "}
                                                <strong>Duration: </strong> <span>{parse(course.duration)}</span>
                                            </li>

                                            <li>
                                                <span className="icon fal fa-home-lg-alt" />{" "}
                                                <strong>Form fee:</strong> <span>{parse(course.formFee)}</span>
                                            </li>

                                            <li>
                                                <span className="icon fal fa-book" />{" "}
                                                <strong>Admission fee:</strong> <span>{parse(course.admissionFee)}</span>
                                            </li>

                                            <li>
                                                <span className="icon fal fa-user" />{" "}
                                                <strong>Development Fee:</strong> <span>{parse(course.developmentFee)}</span>
                                            </li>

                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <strong>Tuition Fee: </strong> <span>{parse(course.tutionFee)}</span>
                                            </li>

                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <strong>Exam Fee: </strong> <span>{parse(course.examFee)}</span>
                                            </li>

                                            {
                                                lopUrl
                                                && 
                                                <li className='lop-link'>
                                                    <span className="icon fal fa-regular fa-file-pdf" />{" "}
                                                    <strong>Course LOP: </strong>
                                                    {" "}
                                                    <a className='ml-6' download href={lopUrl}><span className=" fal fa-solid fa-download" /></a>
                                                    
                                                </li>
                                            }


                                            <li>
                                                <strong>Total Course Costing: {parse(course.totalFee)}</strong>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First